import _cloneDeep from 'lodash/cloneDeep';

export enum PackageName {
  PremiumMonthly = 'premium-monthly',
  PremiumYearly = 'premium-yearly',
}
export type WRPromoCodePromoType = 'yearly-discount' | 'free-trial' | 'deals';

export enum PromoTypeEnum {
  YearlyDiscount = 'yearly-discount',
  FreeTrial = 'free-trial',
  Deals = 'deals',
}

const PackageNameValues = Object.values(PackageName);

export type PackageCurrency = 'PLN';

export type Package = BasePackageData & Price;

export type BasePackageData = {
  name: PackageName;
  description: string;
  tax: number;
  currency: PackageCurrency;
  recurring: boolean;
  freeCycles?: number;
};

export type Price = {
  amount: number;
  amountNet: number;
  last30DaysAmount: number;
  last30DaysAmountNet: number;
};

const BASE_PRICES: Record<PackageName, Price> = {
  [PackageName.PremiumMonthly]: {
    amount: 2990,
    amountNet: 2431,
    last30DaysAmount: 0,
    last30DaysAmountNet: 0,
  },
  [PackageName.PremiumYearly]: {
    amount: 28700,
    amountNet: 23333,
    last30DaysAmount: 0,
    last30DaysAmountNet: 0,
  },
};

const PROMOTIONS = [
  {
    name: 'spring-2024',
    from: new Date('2024-04-26T09:00:00.901Z'), // GMT+2 11:00
    to: new Date('2024-05-29t21:59:59.901z'), // GMT+2 23:59
    newPrices: {
      [PackageName.PremiumMonthly]: {
        amount: 2990,
        amountNet: 2431,
        last30DaysAmount: 0,
        last30DaysAmountNet: 0,
      },
      [PackageName.PremiumYearly]: {
        amount: 18449,
        amountNet: 14999,
        last30DaysAmount: 0,
        last30DaysAmountNet: 0,
      },
    } as Record<PackageName, Price>,
  },
];

export const PACKAGES: BasePackageData[] = [
  {
    name: PackageName.PremiumMonthly,
    description: 'trans.INFO monthly premium subscription.',
    tax: 23,
    currency: 'PLN',
    recurring: true,
    freeCycles: 1,
  },
  {
    name: PackageName.PremiumYearly,
    description: 'trans.INFO yearly premium subscription.',
    tax: 23,
    currency: 'PLN',
    recurring: false,
  },
];

export const getBasePrice = (packageName: PackageName) => {
  return BASE_PRICES[packageName];
};

const timeDiff30days = 30 * 24 * 60 * 60 * 1000;

const last30dayPromotions = (now: number) => {
  // promotions that ended within the last 30 days from date argument (in milliseconds)
  const currentPromotionNames = PROMOTIONS.filter((promotion) => {
    // promotion started before now
    return (
      now <= promotion.to.getTime() &&
      // promotion started ends in the future
      now >= promotion.from.getTime()
    );
  }).map((promotion) => promotion.name);

  return PROMOTIONS.filter((promotion) => {
    const timestamp30daysAgo = now - timeDiff30days;

    // promotion was valid
    return (
      promotion.from.getTime() < promotion.to.getTime() &&
      // promotion ended within the last 30 days (or will end in the future)
      timestamp30daysAgo <= promotion.to.getTime() &&
      // promotion started before now (so it's not a future promotion)
      now >= promotion.from.getTime() &&
      // promotion is not current
      !currentPromotionNames.includes(promotion.name)
    );
  });
};

const last30dayBestPrices: (date: number) => Record<PackageName, Price> = (date: number) => {
  const prices = _cloneDeep(BASE_PRICES);
  const promotions = last30dayPromotions(date);

  promotions.forEach((promotion) => {
    PackageNameValues.forEach((packageName) => {
      if (promotion.newPrices[packageName].amountNet < prices[packageName].amountNet) {
        prices[packageName].amountNet = promotion.newPrices[packageName].amountNet;
        prices[packageName].amount = promotion.newPrices[packageName].amount;
      }
    });
  });

  return prices;
};

export const getCurrentPrices = () => {
  // best option would be to have different promotions affecting different packages
  // but copilot refuses to cooperate, so each promotion must have both packages
  const currentPromotion = PROMOTIONS.find((promotion) => {
    const now = new Date();
    return now >= promotion.from && now <= promotion.to;
  });

  const currentPrices = currentPromotion?.newPrices || BASE_PRICES;

  // Check if there was promotion in the past, that influence last30DaysAmount & last30DaysAmountNet
  const lastBestPrices = last30dayBestPrices(Date.now());
  PackageNameValues.forEach((packageName) => {
    currentPrices[packageName].last30DaysAmount = lastBestPrices[packageName].amount;
    currentPrices[packageName].last30DaysAmountNet = lastBestPrices[packageName].amountNet;
  });

  return currentPrices;
};

export const ALWAYS_PREMIUM_EMAILS = [
  'zsofia.polos@gmail.com',
  'rroszkowski@trans.eu',
  'mac.wron@gmail.com', // ? delete after 26.09.2024
];

export const ALWAYS_PREMIUM_DOMAINS = [
  'trans.eu',
  'trans.info',
  'transcash.eu',
  'bonabanco.com',
  'transbrokers.eu',
  'truckerapps.eu',
  'goodloading.com',
  'cargoon.eu',
  'transinsurance.eu',
  'transcards.eu',
  'truckerslife.eu',
  'polskiedzieci.org',
  'pitd.org.pl',
  'tckancelaria.eu',
  'pactus.eu',
].filter((domain) => process.env.NODE_ENV === 'production' || domain !== 'trans.info');

export const isAlwaysPremium = (email?: string) => {
  if (!email) {
    return false;
  } else {
    return ALWAYS_PREMIUM_EMAILS.includes(email || '') || ALWAYS_PREMIUM_DOMAINS.includes(email?.split('@')[1] || '');
  }
};
